const links = [
  { url: '/', label: 'Home' },
  { url: '/services', label: 'Services' },
  { url: '/portfolio', label: 'Portfolio' },
  // { url: '/testimonials', label: 'Testimonials' },
  { url: '/contact', label: 'Contact' },
  { url: '/about', label: 'About' },
];

export default links;
